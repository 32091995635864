<template>
  <v-container fluid class="ma-0 pa-0">
    <Header v-bind:pageTitle="pageTitle"></Header>
    <v-container>
      <v-row align="center" justify="center" class="pb-8">
        <v-col class="text-center primary--text" lg="4" sm="12" cols="12">
          <h1>Define Expectations</h1>
        </v-col>
        <v-col class="text-center primary--text" lg="4" sm="12" cols="12">
          <h1>Document the Process</h1>
        </v-col>
        <v-col class="text-center primary--text" lg="4" sm="12" cols="12">
          <h1>Verify the Results</h1>
        </v-col>
      </v-row>

      <p>
        Proteus helps sponsors of pension and group retirement programs
        establish and maintain robust governance frameworks and processes
        aligned with the Canadian Association of Pension Supervisory Authorities
        (CAPSA) governance guidelines and industry best practices.
      </p>
      <p>
        Our governance solutions are designed to help your organization comply
        with regulatory requirements and demonstrate to stakeholders that their
        fiduciary duties have been met.
      </p>
      <p>
        There is no one size fits all approach to good governance. We cater to
        the needs of your organization. For many organizations, Proteus acts as
        the secretary for your Pension or Retirement Plan Committee and provides
        the following support:
      </p>
      <div class="pa-8">
        <ul>
          <li>
            Documenting roles and responsibilities in written governance policy
            and procedures manual
          </li>
          <li>Facilitating regular meetings and setting agendas</li>
          <li>Monitoring investment performance versus stated objectives</li>
          <li>Documenting decisions</li>
          <li>Host secure document storage portal</li>
          <li>Pension audit support</li>
          <li>Governance transparency reports to stakeholders</li>
          <li>Delivering education to Committee and/or management</li>
        </ul>
      </div>
      <p>
        Proteus also conducts pension and retirement governance reviews. These
        reviews provide plan sponsors with an objective third-party perspective
        to identify gaps with industry best practices. We will then work with
        the plan sponsor to develop an action plan to make improvements or take
        corrective measures.
      </p>
      <v-row class="pa-5">
        <v-col cols="12" md="8" offset-md="2" lg="6" offset-lg="3">
          <v-card shaped justify="center" align="center">
            <v-card-text class="pa-8 justify-center title primary--text">
              “Success without governance or process is often called “chance”
              and “chance” is not a successful business model.”
              <br />–Peter Henry, Founder
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row class="pt-5">
        <v-col>
          <h3>
            Check out our tips for meeting CAPSA’s governance guidelines at
            Benefits Canada:
          </h3>
        </v-col>
      </v-row>
      <v-row class="pb-10">
        <v-col v-for="item in guidelines" :key="item.link" lg="4" md="6" sm="12" cols="12">
          <v-card justify="center" align="center" :href="item.link" target="_blank">
            <v-card-text class="pa-8 justify-center headline primary--text">{{ item.name }}</v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <ContactUsButton></ContactUsButton>
    <Footer></Footer>
  </v-container>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import ContactUsButton from '@/components/ContactUsButton.vue'

export default {
  name: 'Governance',
  data: () => ({
    pageTitle: 'Governance',
    guidelines: [
      {
        link:
          'https://www.benefitscanada.com/pensions/cap/tips-for-meeting-capsas-pension-governance-guidelines-112665',
        name: 'Guidelines Part 1'
      },
      {
        link:
          'https://www.benefitscanada.com/pensions/governance-law/tips-for-meeting-capsas-pension-governance-guidelines-part-ii-116591',
        name: 'Guidelines Part 2'
      },
      {
        link:
          'https://www.benefitscanada.com/pensions/cap/tips-for-meeting-capsas-pension-governance-guidelines-part-iii-121563',
        name: 'Guidelines Part 3'
      }
    ]
  }),
  components: {
    Header,
    Footer,
    ContactUsButton
  }
}
</script>

<style></style>
