<template>
  <v-container fluid class="ma-0 pa-0">
    <v-row class="mb-4">
      <v-col md="9" sm="9" cols="6">
        <svg viewBox="0 0 8600 400" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0 0H8600L7501.29 400H0V200V0Z" fill="#0A2E41" />
        </svg>
      </v-col>
    </v-row>
    <h1 class="pl-12 display-2 .font-regular">{{pageTitle}}</h1>
    <v-row class="mb-4">
      <v-col md="9" offset-md="3" sm="9" offset-sm="3" cols="8" offset="4">
        <svg viewBox="0 0 8600 400" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1036.72 0H8600V400H0L1036.72 0Z" fill="#FFBF59" />
        </svg>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'Header',
  props: ['pageTitle']
}
</script>
<style scoped>
svg {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}
#bottom-block {
  margin: 0;
  padding: 0;
}
</style>
