<template>
    <v-container fluid class="ma-0 pa-0">
        <Header v-bind:pageTitle="pageTitle"></Header>
        <v-container>
            <h2 >Insurance</h2>
            <p>As a subsidiary of the worlds 5th largest Property and Casualty broker and Canada’s largest Group Benefits broker, we have access to unparalleled resources to help your organization develop a strategy to cover all of your risks and structure the benefits program to achieve your organizational goals.</p>
            <v-row>
                <v-col v-for="item in insurance" :key="item.name" lg="4" md="6" sm="12" cols="12">
                <v-card
                class="ma-3">
                <v-card-title>
                    {{item.name}}
                </v-card-title>
                <v-card-text>
                    {{item.description}}
                </v-card-text>
                </v-card>
                </v-col>
            </v-row>
            <br/>
            <h2>Employee Benefits</h2>
            <p>Our experts can help your organization with all your group benefits needs. This includes:</p>
            <ul>
                <li>Strategic Benefits Planning</li>
                <li>Health and Performance</li>
                <li>HR Technology and Benefits Administration</li>
                <li>Pharmacy Benefits Management</li>
            </ul>
        </v-container>
        <ContactUsButton></ContactUsButton>
        <Footer></Footer>
    </v-container>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import ContactUsButton from '@/components/ContactUsButton.vue'

export default {
  name: 'Insurance & Employee Benefits',
  data: () => ({
    pageTitle: 'Insurance & Employee Benefits',
    insurance: [{
      name: 'General Liability',
      description: 'It’s a basic fact of doing business: as much as you count on commercial property insurance to be there for you in the event of catastrophic damage to your facilities, you also need to protect your organization from potentially ruinous lawsuits that can arise as a result of accidents or negligence.'
    },
    {
      name: 'Commercial & Property',
      description: 'Commercial property insurance coverage may include losses resulting from weather-related events, building collapse, explosion, civil commotion, equipment breakdown, or damage caused by vehicles, vessels, aircraft and vandalism.'
    },
    {
      name: 'Directors & Officers',
      description: 'Directors and officers insurance can cover your leadership’s defense costs and damages if they are named in lawsuits resulting directly from their decisions.'
    },
    {
      name: 'Cyber',
      description: 'Cyber insurance can help restore employee and customer identities, recover compromised data and repair damaged computers and networks, whether your business is the victim of a data breach, password attack, ransomware or phishing attack.'
    },
    {
      name: 'Business Interruption',
      description: 'Property, auto and other forms of commercial insurance will cover the cost to rebuild or replace damaged property — but not the losses that can occur while your business is disrupted. Business interruption insurance covers net profit, ongoing expenses and even extraordinary expenses when a business is disrupted by natural disasters, terrorism, employee fatality and even executive kidnapping.'
    },
    {
      name: 'Employment Practices Liability',
      description: 'Covers an employers’ defense costs and losses from employment-related claims, including wage and hour disputes and allegations of discrimination, harassment, retaliation and unlawful termination.'
    }
    ]
  }),
  components: {
    Header,
    Footer,
    ContactUsButton
  }
}
</script>

<style>

</style>
