<template>
  <v-content>
    <v-container class="fill-height" fluid>
      <v-row
        rows="1"
        row-height="1000"
        class="pt-12"
        align="center"
        justify="center"
      >
        <v-col cols="12" sm="8" md="6" lg="4">
          <v-form @submit.prevent="login" id="login-form">
            <v-card>
              <v-toolbar color="primary" dark flat>
                <v-toolbar-title>Client Portal Login</v-toolbar-title>
              </v-toolbar>
              <v-card-text>
                <v-alert v-if="showError" type="error"
                  >Invalid email and/or password. Please retry.</v-alert
                >

                <v-text-field
                  v-model="email"
                  id="email"
                  label="Username"
                  name="email"
                  prepend-icon="mdi-account"
                  email
                />
                <v-text-field
                  v-model="password"
                  id="password"
                  label="Password"
                  name="password"
                  prepend-icon="mdi-lock"
                  type="password"
                />
              </v-card-text>
              <v-card-actions class="justify-center">
                <v-btn type="submit" form="login-form" color="secondary"
                  >Login</v-btn
                >
              </v-card-actions>
              <v-card-actions class="justify-center">
                <v-progress-circular
                  v-if="loading"
                  indeterminate
                  color="primary"
                ></v-progress-circular>
              </v-card-actions>
            </v-card>
          </v-form>
        </v-col>
      </v-row>
    </v-container>
  </v-content>
</template>

<script>
import router from '../router'

export default {
  name: 'login-form',
  data: () => ({
    email: '',
    password: '',
    showError: false,
    loading: false
  }),
  methods: {
    login () {
      // eslint-disable-next-line no-console
      this.loading = true
      this.showError = false
      const data = { email: this.email, password: this.password }
      this.$store
        .dispatch('login', data)
        .then(() => router.push('/client-portal'))
        // eslint-disable-next-line no-console
        .catch(() => {
          this.loading = false
          this.showError = true
        })
    }
  }
}
</script>

<style></style>
