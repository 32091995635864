<template>
  <v-container fluid class="ma-0 pa-0">
    <Header v-bind:pageTitle="pageTitle"></Header>
    <v-container>
      <v-row align="center" justify="center" class="pb-8">
        <v-col class="text-center primary--text" lg="4" sm="12" cols="12">
          <h1>Technology Driven</h1>
        </v-col>
        <v-col class="text-center primary--text" lg="4" sm="12" cols="12">
          <h1>Data Verified</h1>
        </v-col>
        <v-col class="text-center primary--text" lg="4" sm="12" cols="12">
          <h1>Innovation First</h1>
        </v-col>
      </v-row>
      <p>Investment performance monitoring is an integral piece of the overall governance of an investment program. We have developed and designed our investment performance reports with ongoing feedback from our clients. Our reports are presented in a manner to help you assess whether your portfolios and investment managers are on track and meeting your specific objectives.</p>
      <p>Our reporting infrastructure is built from our proprietary cloud-based SQL database and Web Application. This results in stronger data integrity than reports built in excel workbooks leading to more accurate results for our clients.</p>
      <p>Our reporting capabilities encapsulate our broader philosophy centred on accuracy, timeliness, and innovation. Our reporting software is designed and built internally which enables us to make frequent updates to our systems on the fly to enhance client deliverables without interruption.</p>
      <!-- <p>We are constantly updating and improving our systems to continue to be both secure and flexible.  This gives us a strong foundation to build on and allows us to keep up to date with the latest technological changes without the need to start over. Our reporting software is designed and built internally by our technology team which provides us with full control over how data is delivered and allows us to add new features and updates to meet your needs.</p> -->
    </v-container>
    <ContactUsButton></ContactUsButton>
    <Footer></Footer>
  </v-container>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import ContactUsButton from '@/components/ContactUsButton.vue'

export default {
  name: 'Investment Reporting',
  data: () => ({
    pageTitle: 'Investment Reporting'
  }),
  components: {
    Header,
    Footer,
    ContactUsButton
  }
}
</script>

<style>
</style>
