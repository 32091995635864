<template>
  <v-container fluid class="ma-0 pa-0">
    <Header v-bind:pageTitle="pageTitle"></Header>
    <v-container>
      <v-row>
        <v-col
          v-for="employee in employees"
          :key="employee.name"
          lg="4"
          md="6"
          cols="12"
        >
          <v-hover v-slot:default="{ hover }">
            <v-card
              class="ma-2"
              id="team-card"
              shaped
              @click="updateDialog(employee.id)"
              :class="{ 'on-hover': hover }"
            >
              <v-img
                v-if="employee.id >= 14"
                :src="employee.imagePath"
                contain
                height="375"
              ></v-img>

              <v-img v-else :src="employee.imagePath" height="375"></v-img>
              <v-card-title>{{ employee.name }}</v-card-title>
              <v-card-subtitle>{{ employee.title }}</v-card-subtitle>
            </v-card>
          </v-hover>
          <v-dialog v-model="employee.dialog" max-width="1250">
            <v-card>
              <div class="d-lg-flex">
                <v-avatar
                  v-if="employee.imagePath"
                  class="ma-6"
                  size="50%"
                  tile
                >
                  <v-img :src="employee.imagePath" height="50%"></v-img>
                </v-avatar>
                <v-card-text class="pt-5">
                  <p class="display-1 text--primary">{{ employee.name }}</p>
                  <p class="display-1 text--primary">{{ employee.title }}</p>
                  <p>
                    <a
                      @click="emailevent(employee)"
                      :href="`mailto:${employee.email}`"
                    >
                      <v-icon large>mdi-mail</v-icon>
                    </a>
                    <v-icon large>mdi-phone</v-icon>
                    <span v-if="employee.phone"> : {{ employee.phone }} </span>
                    <span v-else>: 416-421-3557</span>
                  </p>
                  <p v-html="employee.bio"></p>
                </v-card-text>
              </div>
            </v-card>
          </v-dialog>
        </v-col>
      </v-row>
    </v-container>
    <Footer></Footer>
  </v-container>
</template>

<script>
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
export default {
  name: 'OurTeam',
  data: () => ({
    pageTitle: 'Proteus Team',
    employees: [
      {
        id: 0,
        name: 'Ryan Kuruliak, CFA, CIM, RPA',
        phone: '416-619-8468',
        imagePath: 'https://proteuscdn.azureedge.net/Ryan.4c1994f3.jpg',
        title: 'President',
        dialog: false,
        email: 'rkuruliak@proteusperformance.com',
        bio: '<p>Ryan has been a key member of the Proteus investment consulting practice since 2001, became a shareholder in Proteus in 2012 and a shareholder in Hub International Inc. in 2018. He is also the Chair of the Capital Markets &amp; Asset Mix Committee, and Vice Chair of the Business Development Task Force. Ryan was a member of the Proteus Advisory Board from its inception.</p><p>Ryan is the primary consultant for many of Proteus&rsquo; largest and complex clients including: the majority of Proteus&rsquo; endowment and foundation clients; Canadian Universities; all types of pension plans and Canada&rsquo;s first and largest Employee Life and Health Trust.</p><p>Ryan provides clients with expertise in: asset allocation strategy and implementation; investment manager research, due diligence, evaluation, reporting and search; governance evaluation and implementation; and fiduciary education.</p><p>Ryan has spoken at industry conferences, has been published in numerous industry publications and has contributed regular investment articles as a member of the Benefits Canada Expert Panel.</p><p>Ryan is a holder of the right to use the Chartered Financial Analyst&reg; designation and a member of CFA&reg; Society Toronto. He has earned the Canadian Investment Manager (CIM) designation and has completed the requirements for the Retirement Plans Associate (RPA) designation offered by the International Foundation of Employee Benefits Plans and Dalhousie University. Ryan graduated from Brock University with a First Class Honours Degree in Computing and Business (BCB), specializing in finance.</p>',
      },
      {
        id: 1,
        name: 'Joe Connolly',
        phone: '647-401-5941',
        imagePath: 'https://proteuscdn.azureedge.net/jconn.jpg',
        title: 'Senior Vice President',
        dialog: false,
        email: 'joseph.connolly@hubinternational.com',
        bio: '<p>Joe Connolly joined HUB Ontario as Senior Vice President in HUB Proteus. Joe has over 25 years in the investment industry and specializes in pension and investment consulting. He works with clients across all sectors, including post-secondary schools, multi-employer pension plans, unions, religious organizations, and Indigenous clients.  At his previous firm he was the lead investment consultant for one of the largest Deferred Profit-Sharing Plans in Canada. In addition, Joe developed and implemented the investment governance structure for the some of the largest pension plan windups in Canadian history.  He was the National Practice Leader for First Nations and Indigenous clients, which involved working with Chiefs, Councils, trustees and committees from across Canada.  The work included advising on all aspects of the management of their investments, such as performance evaluation of investment managers, investment policy reviews, trust structure, cash management and spending policies, and investment manager searches.</p><p>Joe began his career as an Economist and Consultant with Nuala Beck & Associates Inc., where he was responsible for all facets of proprietary equity and industry research. The firm’s ground-breaking research methods were widely recognized throughout the investment industry in North America.  He was also acknowledged for his research and assistance with three best-selling books: “Why Canada Wins”, “Excelerate: Growing in the New Economy” and “Shifting Gears: Thriving in the New Economy”.</p><p>Joe is Vice-Chair and previous Chairperson of the Leadership Program Committee of the Association of Canadian Pension Management (ACPM) and was also the Past Chairperson of the Investment Advisory Board of the Financial Services Commission of Ontario (FSCO, now FSRA).  He is a current member of the Editorial Advisory Board of Benefits and Pensions Monitor magazine.</p>',
      },
      {
        id: 2,
        name: 'Gord Lewis, MBA',
        phone: '416-619-8471',
        imagePath: 'https://proteuscdn.azureedge.net/Gord%20Lewis.183ff036.jpg',
        title: 'Strategic Advisor',
        dialog: false,
        email: 'glewis@proteusperformance.com',
        bio: '<p>Gord joined Proteus in 1997 bringing his professionalism and business experience to the Canadian pension plan sponsor community. In his role, he helps deliver pension governance, investment consulting, performance measurement, and investment manager research and search. He was appointed Vice President in 2001, became a shareholder in Proteus in 2006 and President in 2020.</p><p>Gord has been published in major Canadian and international pension and investment publications, spoken as a subject matter expert on numerous occasions at industry conferences, sits on the Plans &amp; Trusts Advisory Panel as well as the Board of Directors for Southlake Regional Health Centre Foundation and is the Chair of the Foundation&rsquo;s Investment Committee. He has also been appointed as a special advisor to the Arctic Inspiration Prize Foundation&rsquo;s Finance Committee, in affiliation with the Rideau Hall Foundation.</p><p>A graduate of the University of Western Ontario in 1987 with a Bachelors degree, Gord continued his studies at Queens University to receive an MBA in 1991.',
      },
      {
        id: 3,
        name: 'Lewis Powell, CFA, CIPM',
        phone: '416-619-8475',
        imagePath: 'https://proteuscdn.azureedge.net/Lewis.4a3497a5.jpg',
        title: 'Senior Consultant',
        dialog: false,
        email: 'lpowell@proteusperformance.com',
        bio: '<p>Lewis is a consultant at Proteus and has been part of the team since 2011.</p><p>Lewis&rsquo; investment expertise with a background in portfolio management and credit analysis has helped him design and implement investment mix structures for Proteus&rsquo; largest clients. He most recently restructured the asset mix of a $550 million jointly trusteed pension plan, as well as the foreign investment component of a $4 billion defined benefit pension plan of a major Canadian bank.</p><p>Lewis is a published thought leader with articles featured in the Pension and Benefits Monitor Magazine, and the International Foundation of Employee Benefit Plans, Plans and Trust magazine.</p><p>Lewis is a holder of the right to use the Chartered Financial Analyst&reg; designation and a member of CFA&reg; Society Toronto. He has also earned the right to use the Certificate in Investment Performance Measurement&trade; designation. Lewis graduated from Brock University&rsquo;s Bachelor of Business Administration Honours program in the first class. Prior to joining Proteus, Lewis worked on the private debt/direct investment team of a large, Canadian, insurance firm.</p>',
      },
      {
        id: 4,
        name: 'Teimaz Binesh, CFA',
        phone: '416-619-8460',
        imagePath: 'https://proteuscdn.azureedge.net/Tei.df1c7205.jpg',
        title: 'Senior Consultant',
        dialog: false,
        email: 'tbinesh@proteusperformance.com',
        bio: '<p>Teimaz is a consultant at Proteus and has been part of the Proteus team since January 2011.</p><p>As a senior member of the Proteus team, Teimaz is part of manager research initiatives, performs due-diligence, and creates/amends in-house databases in terms of qualitative and quantitative metrics. He also had lead consulting responsibilities, while providing additional support as the secondary consultant on many of our clients&rsquo; pension, endowment and foundation plans.</p><p>Teimaz has been involved in the investment consulting business since 2006, covering defined benefit plans, defined contribution plans and other institutional investment structures. He has taken an active role in asset allocation strategy; modeling and implementation; investment manager research, due diligence, evaluation and reporting.</p><p>Teimaz is a holder of the right to use the Chartered Financial Analyst&reg; designation and is a member of CFA&reg; Society Toronto. He received a Bachelor of Commerce, with a major in finance and minor in economics, from Ryerson University in 2004. He continued his education by completing the Canadian Securities Course in 2004.</p><p>Prior to joining Proteus, Teimaz worked as a defined benefits analyst with a large, multi-national benefits consulting firm, focusing on pension plans, endowments and foundations. He also worked as a client account representative in group retirement services with a large Canadian-based insurer.</p>',
      },
      {
        id: 5,
        name: 'Michael Scott, CFA',
        phone: '416-619-8476',
        imagePath: 'https://proteuscdn.azureedge.net/Michael.a8525633.jpg',
        title: 'Senior Consultant',
        dialog: false,
        email: 'mscott@proteusperformance.com',
        bio: '<p>Michael joined Proteus in 2012 and works closely with a number of institutions including defined benefit plans, defined contribution plans, and other institutional investment structures. The scope of his involvement includes asset mix modeling; investment manager reviews, searches and ongoing monitoring; manager research; pension governance initiatives; and monitoring pension legislative updates and developments. Prior to his current role as consultant, Michael was responsible for managing and reviewing the analysis and tasks performed by the team of analysts.</p><p>Michael is a CFA&reg; charterholder. He has completed the Canadian Securities Course (Honors) and he received a Bachelor of Business Administration (BBA) from Wilfrid Laurier University.</p>',
      },
      {
        id: 6,
        name: 'Zach Lee, CFA, CIPM',
        phone: '416-619-8470',
        imagePath: 'https://proteuscdn.azureedge.net/Zach.f7f0c9ba.jpg',
        title: 'Consultant',
        dialog: false,
        email: 'zlee@proteusperformance.com',
        bio: '<p>Zach joined Proteus in June 2016 and works as a Consultant. Prior to his current role as Consultant, Zach was responsible, as Lead Analyst, for managing the workloads of the analyst team along with reviewing the analysis and tasks performed by them.</p><p>Zach is a holder of the right to use the Chartered Financial Analyst&reg; designation, the Certificate in Investment Performance Measurement&trade; designation and he is a member of CFA&reg; Society Toronto.</p><p>Prior to joining Proteus, Zach worked as an analyst at a renewable energy company in Toronto focusing on fundamental company analysis and investor relations. He graduated from Wilfrid Laurier University with an Honours BA in Economics and Financial Management.</p>',
      },
      {
        id: 7,
        name: 'Sean Liss, CFA',
        phone: '416-619-8472',
        imagePath: 'https://proteuscdn.azureedge.net/Sean%20Liss%20(003).png',
        title: 'Consultant',
        dialog: false,
        email: 'sliss@proteusperformance.com',
        bio: '<p>Sean joined Proteus in May 2021 and works as a Consultant. Prior to his current role at Proteus, Sean worked at Willis Towers Watson as a Lead Associate in their investment consulting practice.</p><p>Sean has been involved in the investment consulting business since 2016, covering defined benefit plans, defined contribution plans and other institutional investment structures. He has taken an active role in asset allocation strategy; record keeper research; investment manager research due diligence, evaluation and reporting.</p><p>Sean graduated from Wilfrid Laurier University with a Bachelor of Business Administration with a minor in Economics and is a holder of the right to use the Chartered Financial Analyst&reg; </p>',
      },
      {
        id: 8,
        name: 'Gordie Howe',
        phone: '416-619-8464',
        imagePath: 'https://proteuscdn.azureedge.net/Gordie.1dc15059.jpg',
        title: 'Director Investment Operations',
        dialog: false,
        email: 'ghowe@proteusperformance.com',
        bio: '<p>Gordie joined Proteus in July 2016 and works as a Consultant, along with supporting the investment manager research team.</p><p>Gordie graduated from the University of Toronto Mississauga (UTM) with a Bachelor of Commerce, specializing in Finance.</p><p>Prior to joining Proteus, Gordie interned at a business brokerage and M&amp;A advisory firm in Toronto. In his final year at UTM he served as the Vice President of External Affairs for the Undergraduate Commerce Society, in charge of hosting events and fostering relations with representatives in the financial industry.</p>',
      },
      {
        id: 9,
        name: 'Matthew Cramp, CFA, FRM, CIPM',
        phone: '416-619-8461',
        imagePath: 'https://proteuscdn.azureedge.net/Matt.93d81ccf.jpg',
        title: 'Director of Technology',
        dialog: false,
        email: 'mcramp@proteusperformance.com',
        bio: '<p>Matt joined Proteus in December 2016 and is the Director of Technology.</p><p>Matt is a holder of the right to use the Chartered Financial Analyst&reg; designation, the Certificate in Investment Performance Measurement&trade; designation and is a member of CFA&reg; Society Toronto. Matt also completed the Canadian Securities Course, the Conduct and Practices Handbook Course, and has completed the Financial Risk Manager (FRM) Program.</p><p>Matt graduated from McMaster University with a Bachelors of Science in Mathematics.</p><p>Prior to starting at Proteus, Matt worked at an investment advising firm assisting retail clients, and as an analyst at a practice management software company for North American wealth managers.</p>',
      },
      {
        id: 10,
        name: 'Isa Cristello',
        phone: '416-619-8462',
        imagePath: 'https://proteuscdn.azureedge.net/Isa Low Res.07115f23.jpg',
        title: 'Business Manager',
        dialog: false,
        email: 'icristello@proteusperformance.com',
        bio: '<p>Isa joined Proteus as an office manager in January 2006 and has over 20 years of office administration experience within diverse business sectors.</p><p>Isa&rsquo;s diverse background has been recognized through progressive positions held throughout her career. She has successfully provided administrative support to various levels, including dealing with internal and external clients and contacts up to the executive level.</p><p>Isa graduated from Ryerson University with a degree in business management.</p>',
      },
      {
        id: 11,
        name: 'Simran Kaur, MBA',
        phone: '416-619-8467',
        imagePath: 'https://proteuscdn.azureedge.net/Proteus%20Logo%202.png',
        title: 'Senior Investment Analyst',
        dialog: false,
        email: 'skaur@proteusperformance.com',
        bio: '<p>Simran joined Proteus in April 2021 and works as an Investment Analyst.</p><p>Simran is a 2024 Level III Candidate in the Chartered Financial Analyst (CFA®) Program. She graduated Concordia University with an MBA in Investment Management.</p><p>Prior to joining Proteus, Simran interned as a wealth management analyst in a leading financial services firm in India, Motilal Oswal Securities Limited. She also interned as a financial analyst, responsible for financial research, planning & analysis at a multi-national U.S. based pharmaceutical company, Mylan Laboratories Limited.</p>',
      },
      {
        id: 12,
        name: 'Peter Zheng',
        phone: '416-619-8479',
        imagePath: 'https://proteuscdn.azureedge.net/Proteus%20Logo%202.png',
        title: 'Senior Investment Analyst',
        dialog: false,
        email: 'pzheng@proteusperformance.com',
        bio: '<p></p>Peter joined Proteus in April 2021 and works as an Investment Analyst.</p><p>Peter graduated McMaster University with an honors Bachelor of Commerce with a minor in economics and sustainability studies.</p><p>Prior to joining Proteus, Peter interned as a rotational analyst in the commercial banking division of a Canadian bank. </p>',
      },
    ],
  }),
  methods: {
    updateDialog(id) {
      this.employees[id].dialog = !this.employees[id].dialog;
    },
    emailevent(user) {
      this.$gtag.event('emailEvent', {
        event_label: user.name,
      });
    },
  },
  components: {
    Header,
    Footer,
  },
};
</script>

<style scoped>
#team-card {
  border: 3px solid #ffbf59;
}
#team-card:not(.on-hover) {
  border: none;
}
</style>
