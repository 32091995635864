<template>
  <v-container fluid class="pa-0 ma-0">
    <Header v-bind:pageTitle="pageTitle"></Header>
    <v-container class="pa-10">
      <v-container>
        <p>
          Proteus is a division of HUB International and is a premier innovator
          and provider of investment consulting and governance solutions to
          pension plans, foundations and endowments, not-for-profit
          organizations and corporate pools of capital.
        </p>
        <p>
          Proteus’ sole line of business - providing conflict-free and unbiased
          investment and governance consulting services - generates 100% of our
          revenues.
        </p>
        <p>
          For 25 years, our expertise has remained focused on providing the
          services outlined below to institutional investors in Canada.
          Specifically, we provide only investment consulting services – we do
          not provide actuarial services, investment management, group benefits
          or HR consulting. We specialize in the areas of:
        </p>
        <ul>
          <li>
            Development of client investment philosophy, objectives, risk
            tolerance, governance structure
          </li>
          <li>
            Development and maintenance of investment policy statements and
            governance policy
          </li>
          <li>
            Investment manager review, evaluation, search and ongoing oversight
          </li>
          <li>Determining and maintaining asset mix</li>
          <li>Performance measurement and compliance reporting</li>
          <li>Member communication suppor</li>
          <li>Ongoing proactive advice</li>
          <li>Fiduciary education</li>
        </ul>
      </v-container>
      <h1 class="pt-8">Our History</h1>
      <v-timeline>
        <v-timeline-item v-for="time in timeline" :key="time.title">
          <span slot="opposite">{{ time.date }}</span>
          <v-card class="elevation-2">
            <v-card-title class="headline">{{ time.title }}</v-card-title>
            <v-card-text>{{ time.message }}</v-card-text>
          </v-card>
        </v-timeline-item>
      </v-timeline>
    </v-container>

    <Footer></Footer>
  </v-container>
</template>

<script>
import Header from '@/components/Header'
import Footer from '@/components/Footer'

export default {
  name: 'AboutUs',
  data: () => ({
    posts: [],
    errors: [],
    pageTitle: 'About Us',
    timeline: [
      {
        title: 'Founded',
        date: '1994',
        message:
          'Proteus was founded by Peter Henry, who identified a need for objective unbiased governance and investment consulting services for  the institutional investment landscape'
      },
      {
        title: 'First Client',
        date: '1994',
        message:
          'A large industrial manufacturing firm becomes Proteus’ first client and remains a client to this day'
      },
      {
        title: 'Technology',
        date: '1995',
        message:
          'Proteus creates its first proprietary performance reporting database'
      },
      {
        title: 'Technology',
        date: '1998',
        message:
          'Proteus’ performance reporting database is used as the basis for the Morningstar database'
      },
      {
        title: 'Clients',
        date: '1999',
        message: 'Assets under stewardship reach $1 Billion'
      },
      {
        title: 'Cost Certainty',
        date: '2002',
        message:
          'Proteus’ unique retainer structure provides cost certainty to plan sponsors'
      },
      {
        title: 'Client Portal',
        date: '2004',
        message:
          'An on-line Client Portal is created allowing the firm’s retainer clients immediate access to all their pension related documents'
      },
      {
        title: 'Newsletter',
        date: '2006',
        message:
          'The first Proteus Member Newsletter is distributed to defined contribution plan members'
      },
      {
        title: 'Clients',
        date: '2008',
        message: 'Assets under stewardship reach $10 Billion'
      },
      {
        title: 'ViewPoint',
        date: '2013',
        message:
          'Proteus ViewPoint videos released helping to educate plan sponsors on relevant topics and concepts'
      },
      {
        title: 'Services',
        date: '2015',
        message:
          'OCIO search is added to the organization’s list of specialty services'
      },
      {
        title: 'Coast to Coast',
        date: '2015',
        message:
          'Proteus’ retainer clients now stretch from St. John’s to Vancouver'
      },
      {
        title: 'Technology',
        date: '2017',
        message:
          'New technology platform is introduced for reporting which automates collection of data, calculations, and report generation'
      },
      {
        title: 'Clients',
        date: '2020',
        message: 'Assets under stewardship reach $20 Billion'
      }
    ],

    thing2: []
  }),

  components: {
    Header,
    Footer
  }
}
</script>
