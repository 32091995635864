<template>
  <v-container id="contact-us" fluid class="ma-0 pa-0">
    <Header v-bind:pageTitle="pageTitle"></Header>
    <v-container class="pa-10">
      <h1 class="pb-5">Here to Help</h1>
      <p>
        Navigating the complexities in today finacial markets is not an easy
        task. Please feel free to let us know if there is anything we can assist
        with.
      </p>
      <p></p>
      <v-row
        rows="1"
        row-height="1000"
        class="pt-12"
        align="center"
        justify="center"
      >
        <v-col class="text-center" lg="7" md="8" sm="10" cols="12">
          <v-form
            id="contactForm"
            @submit.prevent="formSubmit"
            class="justify-center"
          >
            <v-text-field v-model="name" label="Name"></v-text-field>
            <v-text-field v-model="email" label="Email"></v-text-field>
            <v-textarea v-model="message" label="Message"></v-textarea>
            <button>
              <v-btn class="btn" color="primary">Submit</v-btn>
            </button>
          </v-form>
        </v-col>
      </v-row>

      <v-row>
        <v-col lg="1" md="5" sm="7" cols="9">
          <v-dialog
            v-model="dialog"
            width="unset"
            content-class="v-dialog--custom"
          >
            <v-card>
              <v-card-title class="headline secondary" primary-title
                >Message Sent</v-card-title
              >
              <br />
              <v-card-text
                >Thank you for your message. Someone from out team will be in
                contact with you shortly</v-card-text
              >
            </v-card>
          </v-dialog>
        </v-col>
      </v-row>
    </v-container>
    <Footer class="pt-12"></Footer>
  </v-container>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'ContactUs',
  data: () => ({
    name: '',
    email: '',
    message: '',
    dialog: false,
    pageTitle: 'Contact Us'
  }),
  methods: {
    formSubmit () {
      this.$http
        .post(process.env.VUE_APP_BASE_URI + '/contact-us', {
          name: this.name,
          email: this.email,
          message: this.message
        })
        .then((response) => {
          // JSON responses are automatically parsed.
          this.posts = response
          this.name = ''
          this.email = ''
          this.message = ''
          this.dialog = !this.dialog
        })
        .catch((e) => {
          this.errors.push(e)
        })
    }
  },
  components: {
    Header,
    Footer
  }
}
</script>
<style scoped>
.v-dialog--custom {
  width: 100%;
}

/* Desktop */
@media screen and (min-width: 768px) {
  .v-dialog--custom {
    width: 50%;
  }
}
</style>
