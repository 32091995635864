<template>
  <v-container fluid class="ma-0 pa-0">
    <Header v-bind:pageTitle="pageTitle"></Header>
    <v-container>
      <p>
        Our clients utilize our institutional investment concepts and processes
        to ensure their pools of capital are prudently managed in a manner that
        takes into consideration their risk tolerance, a clear understanding of
        the capital’s purpose and a strategic approach to asset allocation.
      </p>
      <p>
        Our clients rely on us to partner with them in overseeing tens of
        millions to billions of dollars of assets. Our expertise in asset
        allocation, combined with our deep investment manager research and the
        industry’s best performance reporting allow fiduciaries to feel
        confident in the structure and oversight being applied to their assets.
      </p>
    </v-container>
    <ContactUsButton></ContactUsButton>
    <Footer></Footer>
  </v-container>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import ContactUsButton from '@/components/ContactUsButton'

export default {
  name: 'CapitalPools',
  data: () => ({
    pageTitle: 'Pools of Capital'
  }),
  components: {
    Header,
    Footer,
    ContactUsButton
  }
}
</script>

<style></style>
