<template>
  <v-container fluid class="ma-0 pa-0">
    <Header v-bind:pageTitle="pageTitle"></Header>
    <v-container>
      <v-row align="center" justify="center" class="pb-8">
        <v-col class="text-center primary--text" lg="4" sm="12" cols="12">
          <h1>Proactive</h1>
        </v-col>
        <v-col class="text-center primary--text" lg="4" sm="12" cols="12">
          <h1>Comprehensive</h1>
        </v-col>
        <v-col class="text-center primary--text" lg="4" sm="12" cols="12">
          <h1>Strategic</h1>
        </v-col>
      </v-row>
      <p>Proteus’ investment consulting services help long term investors align their investment portfolios with their objectives in a conflict-free manner.</p>
      <p>We advise and guide Committees and Boards through investment strategy and policy reviews, monitoring investment performance and portfolio behaviour, help identify gaps and corrective actions, ensure compliance with policies, design portfolios and make asset allocation decisions.</p>
      <p>Our clients utilize our knowledge and experience to drive more informed and sound decision making. Our proven process creates the foundation that leads to long-term success. We take a proactive and thoughtful approach to formulating ideas and communicate in a clear and concise manner. We have built our business model strictly around aligning interests with our clients – if you are successful, we are successful.</p>
      <p>Some of the ways we help our clients include:</p>
      <ul>
        <li>Evaluating investments in relation to your firm’s philosophy and objectives</li>
        <li>Developing and documenting principles that guide investment strategy and fund lineup construction</li>
        <li>Evaluating appropriate asset classes</li>
        <li>Portfolio simulation and stress testing</li>
        <li>Reviewing current practices vs. best practices and CAP Guidelines</li>
        <li>Defining performance expectations for each investment option and portfolio</li>
        <li>Reporting returns vs. appropriate market benchmarks and peer groups</li>
        <li>Style, risk and attribution analysis</li>
        <li>Reporting on fund manager organizational changes</li>
        <li>Providing an economic and markets overview and contextual observations regarding fund performance</li>
        <li>Monitoring ongoing compliance with your Statement of Investment Policies and Procedures (SIPP)</li>
      </ul>
    </v-container>
    <ContactUsButton></ContactUsButton>
    <Footer></Footer>
  </v-container>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import ContactUsButton from '@/components/ContactUsButton.vue'

export default {
  name: 'InestmentConsulting',
  data: () => ({
    pageTitle: 'Investment Consulting'
  }),
  components: {
    Header,
    Footer,
    ContactUsButton
  }
}
</script>

<style>
</style>
