<template>
  <v-container fluid class="pa-0 ma-0">
    <Header v-bind:pageTitle="pageTitle"></Header>
    <v-container class="pa-10">
      <v-container>
        <p class="primary--text">
          <b>1. We prove our reliability every day.</b>
        </p>
        <p
          class="pl-10"
        >Our purpose is to ensure our clients always feel completely confident in their plan and governance. By keeping our promises. Always following through. Attending to details. Looking for what’s missing. Taking ownership of all that we do. If we say it, we do it.</p>
        <p>2. We embrace clear communication and collaboration.</p>
        <p
          class="pl-10"
        >We work as a team – with our clients, partners and each other. This demands that we’re always open, candid and transparent. And with a good dash of fun too, because life’s too short. Doing so, and being reliable in what we do, builds trust, which is the cornerstone of the team.</p>
        <p>3. We treat our industry partners fairly and respectfully.</p>
        <p
          class="pl-10"
        >Our partnerships are built to last for years, not quarterly performance cycles. We never embarrass our partners or pass the buck. We engage candidly and respectfully with our partners always.</p>
        <p>4. We always have a jump in our step.</p>
        <p
          class="pl-10"
        >We’re nimble and agile. We move swiftly, not recklessly. We look for things to do (or fix) before they look for us. We respond quickly. We always use our own good judgement to make things happen.</p>
        <p>5. We never cut corners on professionalism.</p>
        <p
          class="pl-10"
        >We earn credibility with clients through our quality, accuracy, objectivity, always being prepared, and even being ‘old school’ when it comes to manners and common courtesies. We never lose sight that each dollar has an owner and objective – and that real money deserves real attention. We always strive to do the right thing.</p>
        <p>6. We say no to B.S.</p>
        <p
          class="pl-10"
        >We have zero tolerance for politics, pettiness, volatility, drama, or egos. We leave that stuff for reality TV.</p>
        <p>7. We push our creativity and curiosity.</p>
        <p
          class="pl-10"
        >We’re always improving, looking for new ways to get better internally and for our clients. We never rest on our laurels. We stay curious on emerging possibilities, innovative ways to grow, and different ways of doing things.</p>
        <p>8. We step up when we’re off base.</p>
        <p
          class="pl-10"
        >We’re fallible. We make mistakes. When they happen, we acknowledge them, own them and fix them. A culture of accountability is far more constructive than one of secrecy or avoidance. Accountability builds our character.</p>
        <p>9. We remember that reputation is everything.</p>
        <p class="pl-10">
          If we’re to inspire complete confidence with our clients, then we need to demonstrate the same amongst ourselves.
          <b>It’s the Proteus way.</b>
        </p>
      </v-container>
    </v-container>
    <Footer></Footer>
  </v-container>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
export default {
  name: 'ProteusWay',
  data: () => ({
    pageTitle: 'The Proteus Way'
  }),
  components: {
    Header,
    Footer
  }
}
</script>

<style>
</style>
