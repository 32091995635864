import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import '@mdi/font/css/materialdesignicons.css'
import '@fortawesome/fontawesome-free/css/all.css'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#0a2e41',
        secondary: '#FFBF59',
        anchor: '#FFFFFF',
        background: '#FFFFFF'
      },
      options: {
        customProperties: true
      }
    }
  }
})
