<template>
  <v-container fluid class="ma-0 pa-0">
    <Header v-bind:pageTitle="pageTitle"></Header>
    <v-container>
      <v-row align="center" justify="center" class="pb-8">
        <v-col class="text-center primary--text" lg="4" sm="12" cols="12">
          <h1>Plan Design</h1>
        </v-col>
        <v-col class="text-center primary--text" lg="4" sm="12" cols="12">
          <h1>Member Engagement</h1>
        </v-col>
        <v-col class="text-center primary--text" lg="4" sm="12" cols="12">
          <h1>Maximized Benefit</h1>
        </v-col>
      </v-row>
      <p>Looking to improve the impact of your retirement plan? We understand your firm makes a significant financial investment in its retirement benefit plan and it is important that its design and structure not only have the greatest chance to increase your members’ retirement benefit, but that they understand and appreciate the significant benefit your firm offers. Whether you have an existing plan or are looking to setup a new plan, we can help ensure your program fits the needs or your organization. We have 25 years of expertise in all of the following plan types:</p>
      <ul>
        <li>Defined Contribution Pension Plans</li>
        <li>Group RRSPs</li>
        <li>Deferred Profit Sharing Plans</li>
        <li>Tax Free Savings Accounts</li>
        <li>Non-Registered Savings Plans</li>
        <li>Employee Profit Sharing Plans</li>
        <li>Employee Stock Purchase Plans</li>
      </ul>
    </v-container>
    <ContactUsButton></ContactUsButton>
    <Footer></Footer>
  </v-container>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import ContactUsButton from '@/components/ContactUsButton.vue'

export default {
  name: 'GroupRetirement',
  data: () => ({
    pageTitle: 'Group Retirement Plans'
  }),
  components: {
    Header,
    Footer,
    ContactUsButton
  }
}
</script>

<style>
</style>
