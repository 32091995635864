<template>
  <v-container fluid class="ma-0 pa-0">
    <Header v-bind:pageTitle="pageTitle"></Header>
    <v-container>
      <v-row>
        <v-col v-for="faq in questions" :key="faq.id" cols="3">
          <v-hover v-slot:default="{ hover }">
            <v-card
              id="question-card"
              class="ma-2 color-primary text-center align-center"
              shaped
              @click="updateDialog(faq.id)"
              height="200px"
              :class="{ 'on-hover': hover }"
            >
              <v-card-text class="headline text-center justify-center">{{
                faq.question
              }}</v-card-text>
            </v-card>
          </v-hover>
          <v-dialog v-model="faq.dialog" max-width="800">
            <v-card>
              <v-card-title class="pt-5">{{ faq.question }}</v-card-title>
              <v-card-text>{{ faq.anwser }}</v-card-text>
            </v-card>
          </v-dialog>
        </v-col>
      </v-row>
    </v-container>
    <Footer></Footer>
  </v-container>
</template>

<script>
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';

export default {
  name: 'FAQ',
  data: () => ({
    pageTitle: 'Frequently Asked Questions',
    questions: [
      {
        id: 0,
        dialog: false,
        question: 'What is a pension?',
        anwser:
          'A pension is a type of retirement plan that provides monthly income in retirement. Not all employers offer pensions. Government organizations usually offer a pension, and some large companies offer them.With a pension plan, the employer contributes money to the pension plan while you are working. The money will be paid to you, usually as a monthly check in retirement, after you reach a specific retirement age. A formula determines how much pension income you will receive once you are retired.',
      },
      {
        id: 1,
        dialog: false,
        question: 'How do you set up a pension plan as a company?',
        anwser:
          'A pension is a type of retirement plan that provides monthly income in retirement. Not all employers offer pensions. Government organizations usually offer a pension, and some large companies offer them.With a pension plan, the employer contributes money to the pension plan while you are working. The money will be paid to you, usually as a monthly check in retirement, after you reach a specific retirement age. A formula determines how much pension income you will receive once you are retired.',
      },
      {
        id: 2,
        dialog: false,
        question: 'What is a Capital Accumulation Plan (CAP)?',
        anwser:
          'A CAP is a taxassisted investment or savings plan that permits CAP members to make investment decisions among two or more options offered within the plan. Some examples of a CAP are: defined contribution registered pension plan (RPP), group registered retirement savings plan (RRSP), tax-free savings account (TFSA) and a deferred profit sharing plan (DPSP).',
      },
      {
        id: 3,
        dialog: false,
        question: 'Who can establish a CAP Plan?',
        anwser:
          'An employer, trade union and/or association can establish a CAP for the benefit of its employees or members. When an employer, trade union or association establishes a CAP, they are considered the CAP sponsor. If the CAP is a registered pension plan, many of the responsibilities of the CAP sponsor, described in the CAP guidelines, are those of the pension’s plan administrator.',
      },
    ],
  }),
  methods: {
    updateDialog(id) {
      this.questions[id].dialog = !this.questions[id].dialog;
    },
  },
  components: {
    Header,
    Footer,
  },
};
</script>

<style scoped>
#question-card {
  border: 2px solid #ffbf59;
}
#question-card:not(.on-hover) {
  border: none;
}
</style>
