<template>
  <v-container fluid class="ma-0 pa-0">
    <Header v-bind:pageTitle="pageTitle"></Header>
    <v-container pa-12>
      <p>
        Managing a pension plan in this environment is increasingly challenging.
        Not only are you seeing outflows for benefit payments dwarf
        contributions from active members, but low yields and elevated asset
        prices have compounded the need to ensure your investments are working
        and behaving as they should, in a cost efficient manner.
      </p>
      <p>
        Your
        <b>Plan Members</b> will <b>Benefit</b> from the <b>Services</b> and
        <b>Deliverables We Provide</b> to Pension Plans.
      </p>
      <p>
        One challenge currently facing defined benefit pension plans are
        seemingly ever falling interest rates. 30% of global bonds have negative
        yields and locking your money up for 10 years in a Government of Canada
        bond earns you less than targeted inflation levels. These dynamics put
        you between a rock and a hard place in terms of generating more return
        and taking on excessive risk with the Plan’s assets.
      </p>
      <p>
        The risk in your bond portfolio index (the risk mitigating portion of
        your portfolio) has grown dramatically over the last 15 years. Interest
        rate risk has near doubled, the leverage of the underlying balance
        sheets has doubled, and the credit quality as a result has fallen. Most
        would think the compensation in the return expectation has also doubled
        to reflect the risk when in fact, it is not even expected to beat
        inflation. The ‘fixed’ portion of the return, the coupon, has fallen by
        more than half while any capital gains come off the back of either
        falling interest rates or compressing ‘spreads’, which have fallen as
        far as a reasonably healthy economy can go.
      </p>
      <p>
        Proteus is well positioned to help work through these challenges and
        support prudent decision making. We offer objective, independent advice
        that is free from conflict. We are not affiliated with any
        record-keepers, actuaries, third-party administrators or fund managers
        and we do not sell or manage investment products – we pride ourselves on
        remaining conflict free; it is all about you not us.
      </p>

      <v-row justify="center" align="center">
        <v-col justify-center class="primary--text" cols="12">
          <h1 class="pa-4">Our Process</h1>
        </v-col>
      </v-row>
      <v-img :src="image"></v-img>
      <br />
      <v-row>
        <v-col cols="12" md="10" lg="10">
          <v-card shaped justify="center" align="center">
            <v-card-text class="pa-8 justify-center title primary--text">
              <p>
                “The Board of Trustees for Client Pension Benefits Trust Fund is
                responsible for over $600 million dollars of our members’
                pension assets. To help us ensure our investments are
                appropriate for our Plan, we have partnered with Proteus. They
                provide us with performance measurement, investment manager
                reviews and insight into various investment opportunities they
                feel may be of interest to our Board. Our consultants, Gord
                Lewis and Tei Binesh have recently helped us do a complete
                re-work of our asset mix modelling, and investment lineup. The
                reporting they provided to us was comprehensive and relevant and
                they were able to help guide the Board through the many
                decisions we had to make.
              </p>

              <p>
                When we went through the process of identifying the best
                investment managers, Proteus provided a lot of research and
                insight into the various candidates. Now that the strategic
                allocations are complete, we will be looking to Proteus for more
                ideas on how to improve the returns in our Plan. We have been
                very happy with our partnership with Proteus.”
              </p>
              <br />–Client Pension Benefits Trust Fund Chair
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          md="10"
          lg="10"
          offset-cols="0"
          md-offset="2"
          offset-lg="2"
        >
          <v-card shaped justify="center" align="center">
            <v-card-text class="pa-8 justify-center title primary--text">
              <p>
                “I am a Trustee of Client Pension Trust Fund. The Pension Trust
                Fund has worked with Proteus Performance for over eight years. I
                have found the Proteus Performance team, led by Gord Lewis, to
                be insightful, professional, proactive and overall very helpful.
                Their work has included understanding of the plan’s risk
                tolerance, education around various investment solutions,
                efficient frontiers for our strategic asset mix and investment
                manager research. I believe Proteus Performance understands
                multi-employer pension trusts and has worked with our board in a
                diligent manner. I feel that Proteus Performance understands the
                management of the portfolio and advises trustees, like me, to
                lessen risk an increase return of plan assets.”
              </p>
              <br />–Client Pension Trust Fund Trustee Member
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <ContactUsButton></ContactUsButton>
    <Footer></Footer>
  </v-container>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import ContactUsButton from '@/components/ContactUsButton'
// import DBPensionCharts from '@/components/clients/DBPensionCharts'
export default {
  name: 'Pensions',
  data: () => ({
    pageTitle: 'Defined Benefit Pensions',
    image: require('@/assets/DB Investment Process.png')
  }),
  components: {
    Header,
    Footer,
    ContactUsButton
    // DBPensionCharts
  }
}
</script>

<style></style>
