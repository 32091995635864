<template>
  <v-container fluid class="ma-0 pa-0">
    <Header v-bind:pageTitle="settings.clientName"></Header>
    <v-row class="pl-10">
      <v-col>
        <h3 v-if="settings.consultantName">
          Your Consultant: {{ settings.consultantName }}
        </h3>
        <h3 v-if="settings.consultantEmail">
          Email: {{ settings.consultantEmail }}
        </h3>
        <h3 v-if="settings.dataProvider">
          Record Keeper: {{ settings.dataProvider }}
        </h3>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="6" class="pa-10">
        <v-text-field
          v-model="search"
          label="Search"
          clearable
          clear-icon="mdi-close-circle-outline"
        ></v-text-field>
        <v-treeview
          v-model="tree"
          :search="search"
          :filter="filter"
          :open="open"
          :items="files"
          activatable
          item-key="name"
          open-on-click
        >
          <template v-slot:prepend="{ item, open }">
            <v-icon v-if="!item.name.includes('.')" color="secondary">{{
              open ? "mdi-folder-open" : "mdi-folder"
            }}</v-icon>
            <v-icon v-else color="primary">{{
              fileIcons[item.path.fileType]
            }}</v-icon>
          </template>
          <template v-slot:label="{ item }">
            <div v-if="item.name.includes('.')">
              <v-btn text :href="item.path.fileURL" target="_blank">{{
                item.name
              }}</v-btn>
            </div>
            <div v-else>{{ item.name }}</div>
          </template>
        </v-treeview>
      </v-col>

      <v-col cols="6" class="pa-10">
        <v-text-field
          value="Quick View"
          readonly
          single-line
          clear-icon="mdi-close-circle-outline"
        ></v-text-field>
        <v-treeview
          v-if="meetingfiles.length"
          v-model="tree2"
          :open="['Meeting Dates', 'Next Meeting']"
          open-on-click
          :items="meetingfiles"
          item-key="name"
        >
          <template v-slot:prepend="{ item, open }">
            <v-icon v-if="!item.name.includes('.')" color="secondary">{{
              open ? "mdi-folder-open" : "mdi-folder"
            }}</v-icon>
            <v-icon v-else color="primary">{{
              fileIcons[item.path.fileType]
            }}</v-icon>
          </template>
          <template v-slot:label="{ item }">
            <div v-if="item.name.includes('.')">
              <v-btn text :href="item.path.fileURL" target="_blank">{{
                item.name
              }}</v-btn>
            </div>
            <div v-else>{{ item.name }}</div>
          </template>
        </v-treeview>

        <v-treeview
          v-else
          v-model="tree2"
          :open="['Performance Reports']"
          open-on-click
          open-all
          :items="performanceReports"
          item-key="name"
        >
          <template v-slot:prepend="{ item, open }">
            <v-icon v-if="!item.name.includes('.')" color="secondary">{{
              open ? "mdi-folder-open" : "mdi-folder"
            }}</v-icon>
            <v-icon v-else color="primary">{{
              fileIcons[item.path.fileType]
            }}</v-icon>
          </template>
          <template v-slot:label="{ item }">
            <div v-if="item.name.includes('.')">
              <v-btn text :href="item.path.fileURL" target="_blank">{{
                item.name
              }}</v-btn>
            </div>
            <div v-else>{{ item.name }}</div>
          </template>
        </v-treeview>
      </v-col>
    </v-row>
    <Footer></Footer>
  </v-container>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import axios from "axios";
export default {
  name: "ClientPortal",
  data: () => ({
    open: ["Performance Reports"],
    tree2: [],
    tree: [],
    settings: "",
    search: null,
    clientName: "Client Name",
    fileIcons: {
      PDF: "mdi-file-pdf",
      doc: "mdi-file-document-outline",
      docx: "mdi-file-document-outline",
      json: "mdi-json",
      pdf: "mdi-file-pdf",
      png: "mdi-file-image",
      txt: "mdi-file-document-outline",
      xls: "mdi-file-excel",
      ppt: "mdi-file-powerpoint",
      pptx: "mdi-file-powerpoint",
    },
    files: [],
    meetingfiles: [],
    performanceReports: [],
  }),
  created() {
    axios
      .get(process.env.VUE_APP_BASE_URI + "get-files")
      .then((response) => {
        // JSON responses are automatically parsed.
        this.files = response.data;
        for (const i in response.data) {
          if (
            ["Meeting Dates", "Next Meeting"].includes(response.data[i].name)
          ) {
            this.meetingfiles = [response.data[i]];
            this.meetingfiles[0].children = this.meetingfiles[0].children.sort(
              (a, b) =>
                a.name.localeCompare(b.name, "en", {
                  numeric: true,
                  sensitivity: "base",
                })
            );
          } else if (["Performance Reports"].includes(response.data[i].name)) {
            this.performanceReports = [response.data[i]].sort((a, b) =>
              a.name.localeCompare(b.name, "en", {
                numeric: true,
                sensitivity: "base",
              })
            );
            // eslint-disable-next-line no-console
          }
        }
      })
      .catch((e) => {
        this.errors.push(e);
      }),
      this.$http
        .get(process.env.VUE_APP_BASE_URI + "portal-settings")
        .then((response) => {
          // JSON responses are automatically parsed.
          this.settings = response.data;
        })
        .catch((e) => {
          this.errors.push(e);
        });
  },
  computed: {
    filter() {
      return this.caseSensitive
        ? (item, search, textKey) => item[textKey].indexOf(search) > -1
        : undefined;
    },
  },
  components: {
    Header,
    Footer,
  },
};
</script>
<style scoped>
.v-btn {
  text-transform: none !important;
}
.consultant-email {
  color: black;
  text-decoration: none;
}
</style>
