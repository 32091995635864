<template>
  <v-container fluid class="ma-0 pa-0">
    <Header v-bind:pageTitle="pageTitle"></Header>
    <v-container>
      <v-row align="center" justify="center" class="pb-8">
        <v-col class="text-center primary--text" lg="4" sm="12" cols="12">
          <h1>In-Depth</h1>
        </v-col>
        <v-col class="text-center primary--text" lg="4" sm="12" cols="12">
          <h1>Broad Based</h1>
        </v-col>
        <v-col class="text-center primary--text" lg="4" sm="12" cols="12">
          <h1>Unbiased</h1>
        </v-col>
      </v-row>
      <p>Our team is here to help you monitor your investment managers and make sure they’re staying on track to meet your objectives. We are constantly checking to ensure that all the right ingredients are in place for your managers to succeed. We will proactively help you identify emerging problems and, if necessary, be ready to help you find more appropriate investment candidates.</p>
      <p>We have in-depth knowledge of investment managers. Our clients utilize over 275 different investment strategies which span every kind of asset class and investment approach. We help our clients closely follow these managers and we are always looking out for new opportunities.</p>
      <p>The Proteus research method is well-defined and robust. We regularly gather and analyze extensive volumes of qualitative and quantitative information on a large number of managers. Inputs include a wide range of sources including detailed manager questionnaires, research meetings, 3rd party databases and surveys, our independent analyses of performance and portfolio characteristics, and periodic updates and/or announcements from the managers.</p>
      <p>Proteus takes a holistic approach in evaluating managers and considers a wide range of criteria that fall into the following areas:</p>

      <ul>
        <li>Organization’s history, leadership, structure, ownership, and stability</li>
        <li>Strength, stability and leadership of the investment team</li>
        <li>Investment philosophy, style, objectives, security selection process, and consideration of ESG</li>
        <li>Portfolio construction approach and portfolio attributes</li>
        <li>Risk management approach and compliance</li>
        <li>Trading capabilities</li>
        <li>Track record of performance and risk vs. benchmarks, peers, and expectations for the strategy</li>
        <li>Fees, investment vehicle availability, and product capacity</li>
      </ul>
      <ContactUsButton></ContactUsButton>
    </v-container>
    <Footer></Footer>
  </v-container>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import ContactUsButton from '@/components/ContactUsButton.vue'

export default {
  name: 'ManagerResearch',
  data: () => ({
    pageTitle: 'Manager Research'
  }),
  components: {
    Header,
    Footer,
    ContactUsButton
  }
}
</script>

<style>
</style>
