<template>
  <v-container fluid>
    <v-row class="justify-center align-center">
      <h1 class="pa-4">We are here to help.</h1>
      <h1>Let us know how.</h1>
    </v-row>
    <v-row class="justify-center align-center pt-2">
      <button>
        <v-btn class="btn" color="primary" to="/contact-us" x-large
          >Contact Us</v-btn
        >
      </button>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'ContactUsButton'
}
</script>

<style scoped></style>
