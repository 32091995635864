<template>
  <v-container fluid class="ma-0 pa-0">
    <Header v-bind:pageTitle="pageTitle"></Header>
    <v-container>
      <v-row align="center" justify="center" class="pb-8">
        <v-col class="text-center primary--text" lg="4" sm="12" cols="12">
          <h1>Create Plan</h1>
        </v-col>
        <v-col class="text-center primary--text" lg="4" sm="12" cols="12">
          <h1>Monitor Frequently</h1>
        </v-col>
        <v-col class="text-center primary--text" lg="4" sm="12" cols="12">
          <h1>Adjust As Appropriate</h1>
        </v-col>
      </v-row>
      <p>One thing all asset owners have in common is the need to decide what risks to expose the assets to, which to avoid, and the proportion to allocate to each source of risk. We call this choice the asset allocation decision.</p>
      <p>Asset allocation is a strategic (long-term) decision that should be part of the initial portfolio construction decision making. As asset allocation is inarguably the most impactful decision investors make dictating future outcomes, it merits careful attention.</p>
      <p>Our asset allocation solutions help Committees, Boards and investors make these difficult and critical allocation decisions. Our approach is driven by a well defined, robust and repeatable process, which has led to success for many Canadian institutions.</p>
      <p>There isn’t one optimal solution for everyone when it comes to asset mix. Our process begins with a thorough discussion and examination around philosophy, objectives, obligations, time horizon, required return and risk tolerance specific to you, your organization, and your financial position; we first support you as fiduciaries creating a sound governance context for making asset allocation decisions. Good governance is the foundation for everything we do, that foundation leads to success.</p>
      <p>We then seek to balance the objectives and constraints with the investment opportunity set. To define the opportunity set, our Capital Markets Committee incorporates relevant financial market and economic factors into determining various capital market assumptions.</p>
      <p>Our asset / liability modelling tools allow us to utilize this data to analyse and stress test your exact situation and cashflow expectations, breaking down how various asset mix decisions may influence outcomes specific to you.</p>
      <p>Our process answers questions such as:</p>
      <ul>
        <li>What asset classes should we include in the portfolio?</li>
        <li>How much should we allocate to Canadian bonds?</li>
        <li>What is the probability we do not make our goal of fulfilling the obligation?</li>
        <li>How often should we rebalance?</li>
        <li>What is the impact of adding private debt to the portfolio?</li>
        <li>What is the likelihood we would need to make additional contributions to meet the obligation?</li>
        <li>How volatile will our funded status be under various asset mix scenarios?</li>
        <li>How sensitive is our portfolio to changes in interest rates?</li>
      </ul>
    </v-container>
    <ContactUsButton></ContactUsButton>
    <Footer></Footer>
  </v-container>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import ContactUsButton from '@/components/ContactUsButton.vue'
export default {
  name: 'AssetAllocation',
  data: () => ({
    pageTitle: 'Asset Allocation'
  }),
  components: {
    Header,
    Footer,
    ContactUsButton
  }
}
</script>

<style>
</style>
