<template>
  <v-container fluid class="ma-0 pl-0 pr-0 pb-0 pt-12">
    <v-footer color="primary" padless>
      <v-row>
        <v-col md="2" cols="5" class="pl-6">
          <h3 class="white--text">Proteus</h3>
          <ul class="footerlinks">
            <li v-for="link in proteusFooterLinks" :key="link.route">
              <router-link class="route" :to="link.route">{{link.name}}</router-link>
            </li>
          </ul>
        </v-col>
        <v-col md="3" cols="5">
          <h3 class="white--text">Services</h3>
          <ul class="footerlinks">
            <li v-for="link in servicesFooterLinks" :key="link.route">
              <router-link class="route" :to="link.route">{{link.name}}</router-link>
            </li>
          </ul>
        </v-col>
        <v-col>
          <v-card flat tile absolute class="primary white--text text-center">
            <v-card-text>
              <v-btn
                v-for="item in footerIcons"
                :key="item.route"
                class="mx-4 white--text"
                icon
                :href="item.route"
                target="_blank"
              >
                <v-icon size="24px">{{ item.icon }}</v-icon>
              </v-btn>
            </v-card-text>

            <v-card-text class="white--text pt-0">
              <p>Proteus is one of Canada’s top ranked institutional investment consulting and governance service providers. Based in Toronto, we’re a diverse team of professionals, innovators and thought leaders. We are here to listen to your organization’s goals and objectives, understand your priorities, identify key stakeholders and provide your organization with practical and creative solutions.</p>Proteus is a subsidiary of HUB International Inc.
            </v-card-text>
            <v-card-text class="white--text">{{ new Date().getFullYear() }} — Proteus</v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-footer>
  </v-container>
</template>

<script>
export default {
  name: 'Footer',
  data: () => ({
    footerIcons: [
      { icon: 'fab fa-twitter', route: 'https://twitter.com/ProteusConsults' },
      {
        icon: 'fab fa-youtube',
        route: 'https://www.youtube.com/channel/UC0D2iqF-1OY85jYRvdFm9WQ'
      },
      {
        icon: 'fab fa-linkedin',
        route:
          'https://www.linkedin.com/company/proteus-performance-management-inc-/'
      }
    ],
    proteusFooterLinks: [
      { name: 'Contact Us', route: '/contact-us' },
      { name: 'About Us', route: '/about-us' },
      { name: 'Our Team', route: '/our-team' }
      // { name: "FAQ", route: "/FAQ" },
      // {name:'Submit RFP',route:'/submit-rfp'},
    ],
    servicesFooterLinks: [
      {
        name: 'Investment Consulting',
        route: '/services/investment-consulting'
      },
      { name: 'Manager Research', route: '/services/manager-research' },
      { name: 'Governance', route: '/services/governance' },
      { name: 'Investment Reporting', route: '/services/investment-reporting' },
      { name: 'Asset Allocation', route: '/services/asset-allocation' },
      { name: 'Service Provider Search', route: '/services/provider-search' },
      {
        name: 'Group Retirement Consulting',
        route: '/services/group-retirement'
      }
    ]
  })
}
</script>

<style scoped>
.footerlinks {
  list-style: none;
}
.route {
  text-decoration: none;
}
</style>
