<template>
  <v-container fluid class="ma-0 pa-0">
    <Header v-bind:pageTitle="pageTitle"></Header>
    <v-container>
      <h1>Things I need Help</h1>
      <ul class="pa-12">
        <li>Coming up with items for the timeline If we choose to keep it</li>
        <li>Update content - OCIO Page</li>
        <li>Update content - Manager Research Page</li>
        <li>
          Update content - Governance Page
          <b>Assigned: Michael</b>
        </li>
        <li>
          Update content - Investment Reporting Page
          <b>Assigned: Matt/Shaun</b>
        </li>
        <li>
          Update content - Investment Consulting Page
          <b>Assigned: Lewis</b>
        </li>
        <li>
          Update content - Asset Allocation Page
          <b>Assigned: Lewis</b>
        </li>
        <li>
          Update content - Pension Page
          <b>Assigned: Lewis</b>
        </li>
        <li>Update content - Insurance</li>
        <li>Update content - Foundations and endowments</li>
        <li>Update content - Pension UHNW</li>
        <li>Source quotes from clients</li>
        <li>Sourcing FAQ questions and anwsers</li>
        <li>Updated Bios from everyone (All of Proteus)</li>
      </ul>
      <h1>
        This is a list of items that are currently being worked on (no order)
      </h1>
      <ul class="pa-12">
        <li>
          <strike>Updateing the logo to the correct text</strike>
        </li>
        <li>
          <strike>Updating the logo in the top Nav bar</strike>
        </li>
        <li>
          <strike>Reformating the Protus text on the homepage</strike>
        </li>
        <li>
          <strike>Adding a contact us to services and client types</strike>
        </li>
        <li>
          <strike>Adding a contact us to the homepage</strike>
        </li>
        <li>
          <strike>Adding all links to footer search</strike>
        </li>
        <li>Update the login page to have more style</li>
        <li>
          <strike>Update icons for the links on homepage</strike>
        </li>
        <li>
          <strike>Add Hover highlights to our team cards</strike>
        </li>
        <li>fix card alignment issues with different devices</li>
        <li>Update content on all services and client pages</li>
        <li>
          <strike>Create server for api endpoints</strike>
        </li>
        <li>Center the text in the FAQ cards</li>
        <li>
          <strike>Loop through client quotes</strike>
        </li>
        <li>
          <strike
            >add time delay for client quote so they are operatind
            independently</strike
          >
        </li>
        <li>
          <strike>Update our team and bios</strike>
        </li>
        <li>
          Add services drop down to the menu
          <b>Shaun</b>
        </li>
        <li>
          Add hyperlinks to the timeline
          <b>Shaun</b>
        </li>
        <li>
          <strike>Add links to s ( Need to route properly out of site)</strike>
        </li>
        <li>Add area for news and company information</li>
        <li>
          <strike
            >Update formatting on the cards for quotes (larger text etc)</strike
          >
        </li>
        <li>Update Phone numbers of the team</li>
        <li>Add x to close out of cards</li>
        <li></li>
      </ul>
      <h1>Suggestions</h1>
      <ul class="pa-12">
        <li>
          slide in for the contact us on homepage there would be nothing and
          then on scrolling the contact us would come in from the side
        </li>
        <li>
          possible breaks on the our team manager
          research/leadership/consultants/analysts
        </li>
        <li>flip client types and services order on homepage</li>
        <li>
          List broad market index returns somewhere on the page
          <b>Max</b>
        </li>
      </ul>
    </v-container>
    <Footer></Footer>
  </v-container>
</template>

<script>
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';

export default {
  name: 'ToDo',
  data: () => ({
    pageTitle: 'Website Todo',
  }),
  components: {
    Header,
    Footer,
  },
};
</script>
<style scoped>
svg {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}
#bottom-block {
  margin: 0;
  padding: 0;
}
</style>
