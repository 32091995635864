<template>
    <v-container fluid class="ma-0 pa-0">
        <Header v-bind:pageTitle="pageTitle"></Header>
        <v-container>
            <p>Proteus provides High Net Worth individuals, families and family offices with the same quality institutional investment consulting services that our largest most complex institutional clients have come to rely on.</p>
            <p>Our services include:</p>
            <ul>
                <li>Ongoing oversight and reporting on investments and investment managers</li>
                <li>Investment Policy creation and review</li>
                <li>Asset Mix modeling and the introduction of alternative asset classes</li>
                <li>Optimization of the investment structure and investment managers</li>
                <li>Integration of Environmental, Social &amp; Governance (ESG) in Impact Investing criteria into the investment process</li>
                <li>Investment education and development of a multi-year strategy</li>
                <li>Investment manager review &amp; search</li>
            </ul>
            <br/>
            <p>In partnership with our parent organization, Hub International, Proteus is able to complement our investment services with a full suite of specialized insurance services designed specifically for the high-net-work individual.</p>
        </v-container>
        <ContactUsButton></ContactUsButton>
        <Footer></Footer>
    </v-container>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import ContactUsButton from '@/components/ContactUsButton.vue'

export default {
  name: 'HNW',
  data: () => ({
    pageTitle: 'High Net Worth'
  }),
  components: {
    Header,
    Footer,
    ContactUsButton
  }
}
</script>

<style>

</style>
