import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'
import Axios from 'axios'
import VueGtag from 'vue-gtag'

Vue.config.productionTip = false

Vue.prototype.$http = Axios
const token = localStorage.getItem('token')

Vue.use(VueGtag, {
  config: { id: 'UA-170651212-1' }
}, router)

if (token) {
  Vue.prototype.$http.defaults.headers.common.Authorization = 'Bearer ' + token
}

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
