import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    status: '',
    token: localStorage.getItem('token') || '',
    user: {}
  },
  mutations: {
    auth_request (state) {
      state.status = 'loading'
    },
    auth_success (state, token, user) {
      state.status = 'success'
      state.token = token
      state.user = user
    },
    auth_error (state) {
      state.status = 'error'
    },
    logout (state) {
      state.status = ''
      state.token = ''
    }
  },
  actions: {
    async login ({ commit }, user) {
      commit('auth_request')
      await axios
        .post(process.env.VUE_APP_BASE_URI + 'auth/login', user)
        .then((response) => {
          const token = response.data.token
          const user = response.data.user
          // eslint-disable-next-line no-console
          localStorage.setItem('token', token)
          axios.defaults.headers.common.Authorization = 'Bearer ' + token
          commit('auth_success', token, user)
          return response
        })
    }
  },
  getters: {
    isLoggedIn: (state) => !!state.token,
    authStatus: (state) => state.status
  }
})
