<template>
    <v-container fluid class="ma-0 pa-0">
        <Header v-bind:pageTitle="pageTitle"></Header>
            <v-container>
                <p>Proteus proudly supports institutions across the country providing retirement benefits for hundreds of thousands of Canadians. We can support you in developing, managing and optimizing the benefit your organization provides and the outcome your employees enjoy in retirement. We have over 25 years of experience customizing governance and investment consulting solutions for institutional defined contribution plans and Group RSP programs.</p>
                <p>By understanding your organization’s objectives, your membership and the way you run your business, we will help ensure the significant financial investment your firm makes in its retirement program will have the greatest possible impact on both your members’ retirement outcomes and their level of appreciation for the benefit being provided.</p>
                <p>Our expertise covers:</p>
                <ul>
                    <li>Defined Contribution Pension Plans (DC)</li>
                    <li>Group Tax Free Savings Accounts (TFSA), Deferred Profit Sharing Plans (DPSP)</li>
                    <li>Registered Retirement Income Funds (RRIF)</li>
                    <li>Life Income Funds (LIF)</li>
                    <li>Locked-in Retirement Income Funds (LRIF)</li>
                </ul>
                <br/>
                <p>Proteus provides objective independent advice to Plan Sponsors. We do not sell investment product, nor do we have any affiliation with any other service provider, or earn revenue from any other line of business. We are conflict-free in our approach to providing governance support and investment advice. We put our clients first and aim to provide you with the highest level of service, leading governance solutions and best quality investment consulting available.</p>
            </v-container>
        <ContactUsButton></ContactUsButton>
        <Footer></Footer>
    </v-container>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import ContactUsButton from '@/components/ContactUsButton'

export default {
  name: 'DCPensionsGroupBenefits',
  data: () => ({
    pageTitle: 'DC Pensions & Group Retirement'
  }),
  components: {
    Header,
    Footer,
    ContactUsButton
  }
}
</script>

<style>

</style>
