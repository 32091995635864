/* eslint-disable no-unused-vars */
import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store.js'

import HomePage from '@/components/HomePage'
import ContactUs from '@/components/ContactUs'
import OurTeam from '@/components/OurTeam'
import ToDo from '@/components/ToDo'
import FAQ from '@/components/FAQ'
import AboutUs from '@/components/AboutUs'
import ClientLogin from '@/components/ClientLogin'
import ClientPortal from '@/components/ClientPortal'

import ProviderSearch from '@/components/services/ProviderSearch'
import AssetAllocation from '@/components/services/AssetAllocation'
import InvestmentReporting from '@/components/services/InvestmentReporting'
import ManagerResearch from '@/components/services/ManagerResearch'
import InvestmentConsulting from '@/components/services/InvestmentConsulting'
import Governance from '@/components/services/Governance'
import GroupRetirement from '@/components/services/GroupRetirement'

import DBPensions from '@/components/clients/DBPensions'
import DCPensionsGroupBenefits from '@/components/clients/DCPensionsGroupBenefits'
import CapitalPools from '@/components/clients/CapitalPools'
import InsuranceEmployeeBenefits from '@/components/clients/InsuranceEmployeeBenefits'
import Foundations from '@/components/clients/Foundations'
import HNW from '@/components/clients/HNW'
import PresidentsNote from '@/components/PresidentsNote'
import ProteusWay from '@/components/ProteusWay'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'Home',
      component: HomePage
    },
    {
      path: '/about-us',
      name: 'AboutUs',
      component: AboutUs
    },
    {
      path: '/president-note',
      name: 'PresidentNote',
      component: PresidentsNote
    },
    {
      path: '/proteus-way',
      name: 'ProteusWay',
      component: ProteusWay
    },
    {
      path: '/contact-us',
      name: 'ContactUs',
      component: ContactUs
    },
    {
      path: '/our-team',
      name: 'OurTeam',
      component: OurTeam
    },
    {
      path: '/to-do',
      name: 'ToDo',
      component: ToDo
    },
    {
      path: '/faq',
      name: 'FAQ',
      component: FAQ
    },
    {
      path: '/login',
      name: 'ClientLogin',
      component: ClientLogin
    },
    {
      path: '/client-portal',
      name: 'ClientPortal',
      component: ClientPortal,
      beforeEnter: isAuth
    },
    {
      path: '/services/provider-search',
      name: 'ProviderSearch',
      component: ProviderSearch
    },
    {
      path: '/services/governance',
      name: 'Governance',
      component: Governance
    },
    {
      path: '/services/investment-consulting',
      name: 'InvestmentConsulting',
      component: InvestmentConsulting
    },
    {
      path: '/services/manager-research',
      name: 'ManagerResearch',
      component: ManagerResearch
    },
    {
      path: '/services/asset-allocation',
      name: 'AssetAllocation',
      component: AssetAllocation
    },
    {
      path: '/services/group-retirement',
      name: 'GroupRetirement',
      component: GroupRetirement
    },
    {
      path: '/services/investment-reporting',
      name: 'InvestmentReporting',
      component: InvestmentReporting
    },
    {
      path: '/client/pensions',
      name: 'DBPensions',
      component: DBPensions
    },
    {
      path: '/client/endowment-foundations',
      name: 'Foundations',
      component: Foundations
    },
    {
      path: '/client/insurance',
      name: 'InsuranceEmployeeBenefits',
      component: InsuranceEmployeeBenefits
    },
    {
      path: '/client/hnw',
      name: 'HNW',
      component: HNW
    },
    {
      path: '/client/dc-pensions-group-benefits',
      name: 'DCPensionsGroupBenefits',
      component: DCPensionsGroupBenefits
    },
    {
      path: '/client/capital-pools',
      name: 'CapitalPools',
      component: CapitalPools
    }
  ],

  scrollBehavior: function (to) {
    if (to.hash) {
      return {
        selector: to.hash
      }
    } else {
      return { x: 0, y: 0 }
    }
  }
})

function isAuth (_to, _from, next) {
  // eslint-disable-next-line no-console
  console.log(store.getters.isLoggedIn)
  if (store.getters.isLoggedIn) {
    // user doesn't have access token, redirect to login
    next()
  } else {
    // user has access token, user can open the page
    next({ name: 'ClientLogin' })
  }
}

export default router
