<template>
    <v-container fluid class="ma-0 pa-0">
        <Header v-bind:pageTitle="pageTitle"></Header>
        <v-container pa-12>
            <p>Proteus has supported dozens of endowment and foundation clients over the past 25 years, providing services such as:</p>
            <ul>
                <li>Ongoing oversight and reporting on investments and investment managers</li>
                <li>Investment Policy, Spending Policy creation and review</li>
                <li>Optimization of the investment structure and investment managers</li>
                <li>Integration of Environmental, Social &amp; Governance (ESG) in Impact Investing criteria into the investment process</li>
                <li>Fiduciary / Committee education and development of a multi-year strategy</li>
                <li>Asset Mix modeling and the introduction of alternative asset classes</li>
                <li>Investment manager review &amp; search</li>
            </ul>
            <br/>
            <p>Our services are designed allow our endowment and foundation clients to focus on delivering on their core mission, while we ensure the assets are optimized to support the mission and that prudent processes are in place.</p>
        </v-container>
        <ContactUsButton></ContactUsButton>
        <Footer></Footer>
    </v-container>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import ContactUsButton from '@/components/ContactUsButton.vue'

export default {
  name: 'Foundations',
  data: () => ({
    pageTitle: 'Endowments and Foundations'
  }),
  components: {
    Header,
    Footer,
    ContactUsButton
  }
}
</script>

<style>

</style>
