<template>
  <v-container fluid class="pa-0 ma-0">
    <Header v-bind:pageTitle="pageTitle"></Header>
    <v-container class="pa-10">
      <v-container>
        <p>
          Whether you oversee the investments in a corporate pension plan,
          foundation, endowment, balance sheet or your family’s private wealth,
          you need to understand your options and the trade offs between risk
          and reward. To get there, you should be able to rely on objective,
          well researched advice from a trusted partner.
        </p>
        <p>
          For over 25 years, Proteus has been helping clients understand their
          investment objectives, define their portfolio risks, design and
          develop strategies, educate fiduciaries and put it all together to
          ensure their investments are structured in the most appropriate
          portfolio.
        </p>
        <p>
          Our expertise in investments and governance has allowed us to build an
          enviable reputation in the industry, we were ranked in the top
          quartile of the Greenwich Associates 2019 annual survey. Pension and
          investment consulting is our only business and it has been that way
          from the start.
        </p>
        <p>
          During my 22 years with the firm I have seen technology and housing
          bubbles, a global financial crisis, recessions, continually dropping
          interest rates and unexpected market highs. My experience has taught
          me that theoretical situations actually occur. Examples would include
          2020’s global economic shutdown and the price of oil falling below
          zero. During times of prosperity one hesitates to ponder disaster and
          during times of crisis, it is equally difficult to be certain the sun
          will rise. One thing is certain though, our expertise can help prepare
          you for an uncertain future.
        </p>
        <p>
          What can you expect when you partner with Proteus? You should expect
          our team to be professional, transparent, objective, valuable and
          there when you need us. Our firm is governed by a collectively
          established group of principles which we call The Proteus Way (number
          6 seems to be a favourite).
        </p>
        <p>
          Personally, it is a pleasure to work with such a talented, intelligent
          and client centric team.
        </p>
        <p>
          We look forward to working with you. If you have any questions or
          would like to get in touch with me personally.
        </p>
        <p>Sincerely,</p>
        <p>Gord Lewis</p>
        <p>President</p>
      </v-container>
    </v-container>
    <Footer></Footer>
  </v-container>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
export default {
  name: 'PresidentNote',
  data: () => ({
    pageTitle: 'From Our President'
  }),
  components: {
    Header,
    Footer
  }
}
</script>

<style></style>
