<template>
  <v-container fluid class="ma-0 pa-0">
    <Header v-bind:pageTitle="pageTitle"></Header>
    <v-container>
      <v-row align="center" justify="center" class="pb-8">
        <v-col class="text-center primary--text" lg="4" sm="12" cols="12">
          <h1>Unbiased Assessment</h1>
        </v-col>
        <v-col class="text-center primary--text" lg="4" sm="12" cols="12">
          <h1>Best in Class</h1>
        </v-col>
        <v-col class="text-center primary--text" lg="4" sm="12" cols="12">
          <h1>Aligned Objectives</h1>
        </v-col>
      </v-row>
      <p>Proteus helps Boards, Committees, and institutional investors evaluate and select service providers through our various search services. We identify appropriate candidates by listening to your objectives, your needs and how you want to work with your providers.</p>
      <p>Our services include searches for:</p>
      <ul>
        <li>Investment managers</li>
        <li>OCIO solution providers</li>
        <li>Group retirement record-keepers</li>
        <li>Actuarial firms</li>
        <li>Defined benefit administration</li>
        <li>Custodians</li>
      </ul>
      <br />
      <p>Proteus has no affiliation with any third-party provider and as such provides objective, unbiased and conflict free research and advice.</p>
    </v-container>
    <ContactUsButton></ContactUsButton>
    <Footer></Footer>
  </v-container>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import ContactUsButton from '@/components/ContactUsButton.vue'

export default {
  name: 'ProviderSearch',
  data: () => ({
    pageTitle: 'Service Provider Search'
  }),
  components: {
    Header,
    Footer,
    ContactUsButton
  }
}
</script>
<style scoped>
svg {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}
#bottom-block {
  margin: 0;
  padding: 0;
}
</style>
